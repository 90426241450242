<template>
  <van-nav-bar
    :title="`额度 ${total}`"
    right-text="保存"
    @click-right="onSave"
  />
  <div style="margin:7px 0 0 0;">
    <template v-for="item in roles" :key="item">
      <van-field
        v-model="item.amount"
        type="number"
        :label="item.name"
        placeholder="请输入"
      />
    </template>
  </div>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import { Toast } from 'vant'

export default {
  emits: ['select'],
  setup (props, { emit }) {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const state = reactive({
      productId: useRoute.query.productId,
      items: [
        // {
        //   userId: 9527,
        //   name: '闷闷',
        //   phone: '13890289305',
        //   commission: 0
        // }
      ],
      list: [],
      roles: [],
      total: 0,
      storeId: Cookies.get('storeId')
    })
    /*
    const reset = () => {
      state.list = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    */
    const onAdd = () => {
    }
    const onSubmit = (values) => {
      console.log(state.items)
    }
    const onClick = (row) => {
      emit('select', row)
    }
    const onSave = () => {
      post('/product.updateDividend', {
        productId: state.productId,
        dividends: state.roles
      }).then(res => {
        if (res.code === 0) {
          Toast.success()
        } else {
          Toast(res.msg)
        }
      })
    }
    const init = () => {
      post('/staff.list', {
        storeId: state.storeId,
        pageSize: 1000
      }).then(res => {
        state.roles = res.data.content
        post('/product.get', {
          productId: state.productId
        }).then(res => {
          // state.row = Object.assign({}, res.data)
          state.total = res.data.dividendTotal
          state.list = res.data.dividends || []
          for (const item of state.roles) {
            const n = state.list.find(item2 => item2.staffId === item.staffId)
            if (typeof n !== 'undefined') {
              item.amount = n.amount
            } else {
              item.amount = 0
            }
          }
        })
      })
      // post('/product.get', {
      //   productId: state.productId
      // }).then(res => {
      //   state.items = res.data.content
      //   for (const v of res.data.content) {
      //     state.items.push({
      //       dividendId: v.dividendId,
      //       staffId: v.staff.staffId,
      //       phone: v.staff.user.phone,
      //       commission: v.commission,
      //       name: v.name
      //     })
      //   }
      // })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit,
      onClick,
      onAdd,
      onSave
    }
  }
}
</script>

<style scoped>
body {
  background:#ff6600;
}
</style>
